import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  QuickFacts,
  BannerLinks,
  BannerSmall,
  CallUs,
  ProjectPagination,
  Image,
  LernApp,
  HeaderSlider
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import contacts from '../../components/CallUs/DefaultContacts'

import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/header.wko.png'
import image4 from '../../assets/bannerlinks/bannerbox.ikb.jpg'
import image5 from '../../assets/stadtamthall.uebersicht.jpg'
import Roland from '../../assets/roland.teissl.png'

export const frontmatter = {
  title: 'wirtschaftskammer.js',
  slug: '/Projekte/wirtschaftskammer.js'
}

import mobile1 from '../../assets/individual-mockups/Mobile/KFZapp_1.png'
import mobile2 from '../../assets/individual-mockups/Mobile/KFZapp_2.png'
import mobile3 from '../../assets/individual-mockups/Mobile/KFZapp_3.png'

const slides = [
  {
    eachSlide: mobile1,
    alt: "LernApp KFZ"
  },
  {
    eachSlide: mobile2,
    alt: "LernApp KFZ"
  },
  {
    eachSlide: mobile3,
    alt: "LernApp KFZ"
  },

]

const Arbeiterkammer = () => {
  const links = generateBreadcrumbsLinks(
    '/projekte/wirtschaftskammer',
    'Wirtschaftskammer Tirol'
  )
  return (
    <Layout margin={false} color="white">
      <Helmet
        title="Wirtschaftskammer Tirol"
        meta={[
          {
            name: 'description',
            content:
              'Die Wirtschaftskammer Tirol mit ihrer tollen neuen Lernplattform!'
          },
          {
            name: 'keywords',
            content:
              'Wirtschaftskammer Tirol holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, Wirtschaftskammer Tirol, Deine Lehre, Tirol, WKO Tirol, WKO, Lernplattform'
          },
          {
            property: 'og:description',
            content:
              'Die Wirtschaftskammer Tirol mit ihrer tollen neuen Lernplattform!'
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/header.wko-f2567512e317dcd46d1e6e2a947ce7ce.png'
          }
        ]}
      />
      <BannerSmall image={BannerImage} title="Wirtschaftskammer Tirol" />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />

      <TextBox
        title={"Lernapp für Lehrlinge"}
        text={<>
          <p>holzweg hat eine innovative Lern-App entwickelt, die der betriebsinternen Fort- und Weiterbildung dient und besonders auf die Lehrlingsausbildung spezialisiert ist. In Zusammenarbeit mit dem Fachexperten Roland Teißl wurde die Lern-App konzipiert, um die Lehrlingsausbildung für verschiedene Branchen zu optimieren.</p>
          <p>Die wichtigsten Ziele im Überblick:</p>
          <ul>
            <li>
              Unsere App bietet eine gezielte Vorbereitung auf das Fachgespräch der Lehrabschlussprüfung und ist strukturiert nach Modulen der Ausbildungsordnung.
            </li>
            <li>
              Lehrlinge können ihre Antworten überprüfen, Erklärungen erhalten und bei Bedarf unbeantwortete Fragen markieren, um sie wiederholt zu üben.
            </li>
            <li>
              Ein Fragenpool steht außerdem für die Wissensüberprüfung zur Verfügung.
            </li>
            <li>
              Die App ermöglicht zudem offene oder komplexe Fragen, während Ausbilder:innen Einblick in die Ergebnisse nehmen und Feedback geben können.
            </li>
          </ul>
          <p><b>Sie würden gerne Ihren Mitarbeitenden interne Informationen oder branchenweite Standards zur Verfügung stellen? Oder den Einschulungsprozess neuer Mitarbeitenden erleichtern? Ein kleines Intranet mit Chat-Funktion, Kalender oder eine Übersetzungsfunktion wäre der Hit?</b></p>
          <p>Ihren Ideen sind keine Grenzen gesetzt - wir von holzweg entwerfen für Sie eine maßgeschneiderte App, die Ihren Anforderungen und Wünschen entspricht.</p>

        </>
        }
      />
      <LernApp />
        <HeaderSlider
        slides={slides}
        fixed={true}
        />
      <TextBox
        title={<div>Digitales Lernen ohne Grenzen:</div>}
        text={
          <span>
            <i>
              <p>
                Mit der Initiative „Deine Lehre – Apps für die
                Lehrlingsausbildung“ stehen den Lehrlingen, Ausbilderinnen und
                Ausbildern sowie Lehrpersonen des Lehrberufes
                Einzelhandel-Textilhandel digitale Lernmedien zur Verfügung. Das
                Projekt ist so ausgelegt, dass es jederzeit problemlos auf
                andere Lehrberufe ausgeweitet werden kann. Die Apps können auf
                jedem iOS- oder Android Gerät und per Browser auf jedem PC,
                Laptop oder Tablet verwendet werden. Dadurch ist ein Einsatz
                nicht nur im Berufsschulunterricht, sondern auch orts- und
                zeitunabhängig (wie z. B. im Lehrbetrieb, im Internat sowie bei
                internen und externen Schulungen) möglich.{' '}
              </p>
              <p>
                Jedes Thema besteht aus kleinen, in sich geschlossenen
                Lerneinheiten, und beginnt mit einer Übersicht über die zu
                erlangenden Kompetenzen. Wiederholungen in Form von Wissenscheck
                helfen den Lehrlingen, ihren Wissensstand zu festigen und ihren
                Lernfortschritt zu prüfen. Anhand von Praxisaufgaben können Sie
                das Gelernte in beruflichen Situationen anwenden.
                Berufsspezifische Videos runden die App ab. Anhand eines
                Erklärvideos werden die Benutzer:innen in den Aufbau
                und die Struktur der Themen eingeführt.
              </p>
              <p>
                Für die Umsetzung eines Projektes mit so speziellen Inhalten und
                eines so großen Umfanges braucht es einen Partner, der diese
                Anforderungen professionell und zuverlässig umsetzen kann.
                Deshalb haben wir uns für die Firma Holzweg entschieden. Nach
                mehreren ausführlichen Teambesprechungen stand das Konzept und
                das Design der Web-App fest. Im Anschluss daran ging es an die
                technische Umsetzung, für die uns mit dem Programmierer Feras
                Abdulazem und der Designerin Eva Jöchl zwei höchst kompetente
                Mitarbeiter:innen der Firma Holzweg zur Verfügung standen.
                Während des gesamten Projektverlaufes konnten wir sie jederzeit
                kontaktieren und unsere Anliegen vorbringen, die dann umgehend
                umgesetzt wurden. Abschließend wurde das Projekt mehrfach
                getestet und zu unserer vollsten Zufriedenheit übergeben. Die
                Zusammenarbeit mit der Firma Holzweg war stets konstruktiv und
                sehr angenehm.
              </p>
              <p>
                Für Projekte dieser Art können wir die Firma Holzweg bestens
                empfehlen.
              </p>
            </i>
          </span>
        }
      />
      <Image image={Roland} title="" />
      <TextBox
        title=""
        text={
          <span>
            <p>
              <b>Roland Teißl</b>
              <br />
              WKO-Tirol – Projektkoordinator "Deine Lehre"
            </p>
          </span>
        }
      />
      <CallUs contacts={contacts} title="Gerne für Sie da" />
      <TextBox titleMods={['center']} title="Weitere Projekte" text={false} />
      <ProjectPagination
        titleLeft="IKB"
        imageLeft={image4}
        leftLink="/projekte/ikb"
        titleRight="Stadtamt Hall Relaunch"
        imageRight={image5}
        rightLink="/projekte/stadtamthall"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Arbeiterkammer
